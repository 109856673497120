.button-container {
    text-align: center;
}
.button--primary {
    color: #fff;
    background-color: #c60c30;
    border: 2px solid #a71930;
    text-decoration: none;
}
.button {
    height: auto;
    padding: calc((44px - 1.5rem)/2) 0.75rem;
    line-height: 1.5rem;
    font-size: 1rem;
    font-family: Gotham,Arial,sans-serif;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid rgb(215, 21, 58);
    transition: border-color .5s ease,background-color .5s ease,color .5s ease;
}

.button:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(191, 12, 42);
    border-color: transparent;
}
.button:focus {
    outline: rgb(46, 82, 153) solid 2px;
    outline-offset: 2px;
}
@media screen and (min-width: 30em) {
    .button {
        width: auto;
        min-width: 12.5rem;
    }
}
@media screen and (max-width: 29em) {
    .button {
        width: 90%;
        margin-bottom: 1rem;
    }
}