.footer-grey{
    color: rgb(255, 255, 255);
    font-family: Public Sans, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    height: 24px;
    line-height: 24px;
    width: 372px;
}

/*ul {*/
/*    margin: 1.5rem 0;*/
/*    padding: 0;*/
/*    max-width: 769px*/
/*}*/

/*ul {*/
/*    list-style: none*/
/*}*/

.container {
    max-width: 1324px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

.button--link {
    &:focus {
        outline-offset: 1px !important;
        outline: #2e5299 auto 1px !important;
    }
    pointer-events: auto;
}

.global-header__top {
    background: #242934;
    color: #ffffff;
    padding: 15px 10px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    height: 48px;
}
.global-header__main{
    background:#ffffff;

}

.global-header__main .container {
    height: 44px;
    display: flex;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.is-internal{
    font-weight: bold;
}

.global-header__logo-lock-up {
    display: flex;
    margin: 0;
    flex-shrink: 0;
    background: none!important;
    padding: 0!important;
    text-decoration: none;
    align-items: flex-end;
    align-self: center;
    margin-left: 10px;
    /**/
    font-family: Public Sans, Arial, sans-serif;
    font-size: 14px;
    line-height: 19.6px;
    color: #2e2f30;
}



.global-header__logo-lock-up svg {
    flex-shrink: 0;
    overflow: visible
}

.global-header__logo-lock-up span {
    flex-shrink: 0;
    display: block;
    height: 46px;
    width: 1px;
    background: #dee3e5;
    margin: 0 13px
}

.global-footer {
    color: #fff;
    position: relative;
    bottom: 0;
    width: 100%;
}

.global-footer__main {
    background: #646974;
    padding-top: 28px;
    padding-bottom: 28px;
}

@media (min-width:1024px) {
    .footer-nav:after {
        content: "";
        display: table;
        clear: both
    }
    @supports (display:grid) {
        .footer-nav {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: auto 1fr;
            grid-gap: 0 1.5rem
        }
    }
}

.footer-nav a {
    text-decoration: none
}

.footer-nav__level-1>li>a {
    text-transform: uppercase;
    font-weight: 500;
    font-size: .875rem
}

.footer-nav__level-2 {
    margin: 20px 0 0
}

.global-footer__message img {
    width: 33px;
    height: 23px;
    margin-right: 14px;
    display: inline-block
}

.global-footer__message p {
    max-width: none;
    margin: 0;
    font-family: Public Sans, Arial, sans-serif;
    font-size: 14px;
    line-height: 19.6px;
    /*color: #2e2f30;*/
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
}


.global-footer__bottom {
    background: #242934;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}


.global-footer__bottom .inline-nav a {
    font-size: .875rem
}


.global-footer__bottom .inline-nav .is-external,
.global-footer__bottom .inline-nav [href^=http]:not([href*="service.nsw.gov.au"]):not(.is-internal) {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='12' width='12' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0v12h12V0H0zm10.9 10.9H1.1V1.1h9.8v9.8z'/%3E%3Cpath d='M4.4 2.7v1.1h3L2.3 8.9l.8.8 5.1-5.1v3h1.1V2.7H4.4z'/%3E%3C/svg%3E");
    padding-right: 16px
}

.landing-page__section{
    border-radius: 25px;
    border-top: solid  2px #dee3e5;
    transition: border-color 0.5s ease 0s, background-color 0.5s ease 0s, color 0.5s ease 0s;
    background: #f4f7f9;
}

.landing-page__main{
    background:#ffffff;

    flex-grow: 5;
    padding-bottom:400px;
}

.region {
    padding-top: 32px;
    padding-bottom: 32px;
}

.page-title {
    font-size: 2.25rem;
    width: fit-content;
    color: black;
    font-family: Public Sans, Arial, sans-serif;
}

* {
    box-sizing: border-box;
}

.bottom-block{
     display:flex;
     flex-direction: column;
 }

.contentContainer-background{
    background-color:#F4F7F9;
}

.agency-table{
background-color:transparent !important;
}

.triangle-up{
    width: 0;
    height: 0;
    border-left: 0.313rem solid transparent;
    border-right: 0.313rem solid transparent;
    border-bottom: 0.5rem solid #555;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 0.313rem solid transparent;
    border-right: 0.313rem solid transparent;
    border-top: 0.5rem solid #555;
}
